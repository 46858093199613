<template>
  <div class="subscriptions-container">
    <category-block
      v-if="!showMenuNavigator"
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <CategoryTitle :category="category" />
    <div
      class="stepper-container d-flex flex-column flex-sm-row py-3"
      v-if="Object.keys($t('subscriptions')).length > 0"
    >
      <div
        class="d-flex flex-column text-center justify-center align-center h-100 px-2 pt-4"
        v-for="(step, index) in Object.keys($t('subscriptions')).length"
        :key="index"
        :class="{
          'step-separator': index < Object.keys($t('subscriptions')).length - 1
        }"
      >
        <div
          class="primary white--text dot pa-6 rounded-circle d-flex justify-center align-center"
        >
          <span
            class="number font-weight-bold"
            v-html="$t('subscriptions.' + Number(index + 1) + '.number')"
          ></span>
        </div>
        <div>
          <strong
            v-html="$t('subscriptions.' + Number(index + 1) + '.title')"
          ></strong>
        </div>
        <div>
          <p
            class="stepper-description text-body-2"
            v-html="$t('subscriptions.' + Number(index + 1) + '.description')"
          ></p>
        </div>
      </div>
    </div>

    <h4
      class="text-body-1 mt-4 mb-5 font-weight-bold primary--text text-center"
    >
      {{ $t("subscriptionsModule.avaibilityDisclaimer") }}
    </h4>

    <v-list
      id="subscriptionList"
      flat
      class="cart-item-list checkout-item-list"
      v-if="!loading"
    >
      <v-card
        v-for="item in subscriptions"
        :key="item.subscriptionPlanId"
        class="subscription-card d-flex flex-column flex-md-row justify-space-between mb-5"
      >
        <div
          class="cart-item subscription-card-header d-flex flex-column flex-md-row align-center pa-3"
        >
          <v-avatar tile size="150">
            <v-img
              v-if="item.product && item.product.mediaURL"
              :src="item.product.mediaURL"
              :alt="'Immagine prodotto'"
            />
          </v-avatar>
          <v-card-title>
            {{ item.descr }}
          </v-card-title>
        </div>
        <div
          class="card-content pa-2 pa-md-0 d-flex flex-column align-center justify-center"
        >
          <v-card-text
            v-if="!subscriptionActive(item) && item.product"
            class="product-price-text"
          >
            <ProductPrice
              :product="item.product"
              :showDescrWeight="false"
              :showOldPrice="false"
            ></ProductPrice>
          </v-card-text>
          <v-card-subtitle v-if="subscriptionActive(item) && isExpired(item)">
            <div
              class="d-flex justify-center subscription-status font-body-1 font-weight-bold expired"
            >
              <p>
                <b>{{ $t("subscriptionsModule.expiredSubscription") }}</b>
              </p>
            </div>
            <div
              class="d-flex justify-center subscription-expire font-weight-bold text-caption"
            >
              {{ $t("subscriptionsModule.expired") }}
              {{ $dayjs(item.renewalDate).format("D MMMM YYYY") }}
            </div>
          </v-card-subtitle>
          <v-card-subtitle
            class="d-flex flex-column"
            :class="{
              'align-start':
                subscriptionActive(item) && $vuetify.breakpoint.xsOnly
            }"
            v-else-if="
              subscriptionActive(item) && !isExpiring(item) && !isExpired(item)
            "
          >
            <div
              class="d-flex justify-center subscription-status font-body-1 font-weight-bold"
            >
              <p>
                {{ $t("subscriptionsModule.activeSusbscription") }}
              </p>
            </div>
            <div
              class="d-flex justify-center subscription-expire font-weight-bold text-caption"
            >
              Scadenza {{ $dayjs(item.renewalDate).format("D MMMM YYYY") }}
            </div>
            <v-btn
              medium
              depressed
              color="primary"
              class="subs-btn"
              @click.stop.prevent="addSubscriptionPlan(item.subscriptionPlanId)"
              :disabled="
                subscriptionAdded(item.subscriptionPlanId) ||
                  subscriptionAdded(item.subscriptionPlanId) ||
                  (subscriptionActive(item) && !isExpiring(item))
              "
            >
              <span>
                {{ $t("subscriptionsModule.renewSubscription") }}
              </span>
            </v-btn>
          </v-card-subtitle>
          <v-card-actions
            class="v-card-actions d-flex flex-column"
            v-else-if="subscriptionActive(item) && isExpiring(item)"
          >
            <div
              class="d-flex justify-center subscription-status font-body-1 font-weight-bold expiring"
            >
              {{ $t("subscriptionsModule.expiringSubscription") }}
            </div>
            <div
              class="d-flex justify-center subscription-expire font-weight-bold text-caption"
            >
              {{ $t("subscriptionsModule.expiring") }}
              {{ $dayjs(item.renewalDate).format("D MMMM YYYY") }}
            </div>
            <i18n-n
              :value="item.product.priceDisplay"
              format="currency"
              tag="span"
              class="renew-subscription-price formatted-price font-weight-bold py-1 px-3 my-2"
            >
              <template v-slot:currency="slotProps">
                <span class="currency">{{ slotProps.currency }}</span>
              </template>
            </i18n-n>
            <v-btn
              medium
              block
              depressed
              color="primary"
              class="subs-btn expired-btn"
              @click.stop.prevent="addSubscriptionPlan(item.subscriptionPlanId)"
              :disabled="subscriptionAdded(item.subscriptionPlanId)"
            >
              <span>
                {{ $t("subscriptionsModule.renewSubscription") }}
              </span>
            </v-btn>
          </v-card-actions>
          <v-card-actions v-else>
            <div
              v-if="subscriptionOrdered(item)"
              class="d-flex justify-center subscription-status"
            >
              <p>
                {{ $t("subscriptionsModule.orderedSubscription") }}
              </p>
            </div>
            <v-btn
              medium
              depressed
              color="primary"
              class="subs-btn"
              @click.stop.prevent="addSubscriptionPlan(item.subscriptionPlanId)"
              :disabled="
                subscriptionAdded(item.subscriptionPlanId) ||
                  !subscriptionPaid(item)
              "
            >
              <v-icon color="white" class="mr-2">$cart</v-icon>
              <span>
                {{ $t("subscriptionsModule.addToCart") }}
              </span>
            </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-list>

    <v-spacer class="spacer-scroll"></v-spacer>

    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
  </div>
</template>
<style scoped lang="scss">
.subscriptions-container {
  .stepper-container {
    .step-separator {
      border-bottom: 1px solid rgba(0, 0, 0, 0.25);
      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        border-right: 1px solid rgba(0, 0, 0, 0.25);
        border-bottom: none;
      }
    }
  }
  .dot {
    .number {
      position: absolute;
      font-size: 20px;
    }
  }

  .subscription-card {
    gap: 8px;
    .card-content {
      .product-price-text {
        box-sizing: content-box;
      }
      .renew-subscription-price {
        border: 2px solid var(--v-primary-base);
        border-radius: $border-radius-root;
      }
      min-width: 350px;
      @media #{map-get($display-breakpoints, 'xs-only')} {
        min-width: auto;
      }
    }
  }
  .cart {
    position: relative;
    padding-bottom: 56px;
  }

  .subscription {
    border: 2px solid var(--v-primary-lighten1) !important;
    border-radius: 6px;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      img {
        max-width: 100%;
        width: auto;
        height: auto;
      }
    }
  }
  .subscription-status {
    color: #5bbe0d;
  }
  .expiring {
    color: $secondary !important;
  }
  .expired {
    color: #ff0000 !important;
  }
}
</style>
<script>
import RegistrationService from "~/service/userService";
import CategoryTitle from "@/components/category/CategoryTitle.vue";

import productMixins from "~/mixins/product";
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";
import ProductPrice from "@/components/product/ProductPrice.vue";
// import ProductPromo from "@/components/product/ProductPromo.vue";
import catalogService from "@/commons/service/catalogService";

import { mapActions, mapState, mapGetters } from "vuex";
// import { forEachCartItem } from "~/service/ebsn";

import get from "lodash/get";

import subscriptionPlanService from "~/service/subscriptionPlanService";

export default {
  name: "Subscriptions",
  mixins: [login, productMixins, cartInfo, categoryMixins],
  props: { showMenuNavigator: { type: Boolean, default: false } },
  components: {
    CategoryTitle,
    ProductPrice
  },
  data() {
    return {
      dialogInstance: null,
      error: null,
      loading: true,
      giftCertificatesReceived: [],
      emailKey: 1,
      showAlternativesTooltip: false,
      renderKey: 1,
      subscriptions: [],
      userSubscriptions: []
    };
  },
  computed: {
    title() {
      let c = this.selectedCategory ? this.selectedCategory : this.category;
      return get(c, "metaData.category_info.TITLE", c.name);
    },
    subtitle() {
      return get(
        this.category,
        "metaData.category_info.DESCRIPTION",
        this.category.description
      );
    },
    breadcrumbs() {
      let breadCrumbs = [];

      breadCrumbs.push({
        to: { name: "Home" },
        text: "Homepage",
        exact: true
      });
      breadCrumbs.push({
        to: {
          name: "I miei abbonamenti",
          params: {
            pathMatch: this.category.slug
          }
        },
        text: this.category.name,
        exact: true
      });
      return breadCrumbs;
    },
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      getCartStatus: "cart/getCartStatus"
    })
  },
  methods: {
    ...mapActions({
      lockCart: "cart/lockCart",
      loadCart: "cart/loadCart",
      emptyCart: "cart/emptyCart",
      addGiftCode: "cart/addGiftCode",
      sendGiftCode: "cart/sendGiftCode",
      removeGiftCode: "cart/removeGiftCode"
    }),
    async addUserGiftCode(giftCodeId) {
      let cart = await this.addGiftCode(giftCodeId);
      if (cart) {
        this.getUserDetails();
      }
    },
    async sendUserGiftCode(giftCodeId) {
      let cart = await this.sendGiftCode(giftCodeId);
      if (cart) {
        this.getUserDetails();
      }
    },
    async removeUserGiftCode(giftCodeId) {
      let cart = await this.removeGiftCode(giftCodeId);
      if (cart) {
        this.getUserDetails();
      }
    },
    async getUserDetails() {
      const data = await RegistrationService.getUserDetail();
      this.giftCertificatesReceived = data ? data.giftCertificatesReceived : [];
    },
    async getSubscriptionsList() {
      let subscriptions = await subscriptionPlanService.subscriptionPlanList();
      this.subscriptions = subscriptions.subscriptionPlans;
    },
    async getUserSubscriptionsList() {
      let userSubscriptions = await subscriptionPlanService.userSubscriptionPlanList();
      this.userSubscriptions = userSubscriptions.subscriptionPlans
        ? userSubscriptions.subscriptionPlans
        : [];
    },
    async addSubscriptionPlan(subscriptionPlan) {
      let params = {
        subscriptionPlanId: subscriptionPlan
      };
      let result = await subscriptionPlanService.addSubscriptionPlan(params);
      if (
        (result.response && result.response.status == 0) ||
        result.status == 0
      ) {
        this.loadCart();
        // this.reload();
        this.subscriptions = result.data.subscriptionPlans;
      }
    },
    pricePerUnit(product) {
      return (
        product.price *
        parseFloat(
          Number(product.productInfos.WEIGHT_SELLING) /
            catalogService.getMultiplier(product)
        )
      );
    },
    subscriptionOrdered(subscription) {
      let isActive = false;
      let userSubscription = null;
      this.userSubscriptions.filter(ub => {
        if (ub.subscriptionPlanUsers) {
          return ub.subscriptionPlanUsers.filter(ss => {
            if (ub.subscriptionPlanId == subscription.subscriptionPlanId) {
              userSubscription = ss;
            }
          });
        }
      });
      if (
        userSubscription &&
        userSubscription.active == 1 &&
        userSubscription.subscriptionPlanUserStatus &&
        userSubscription.subscriptionPlanUserStatus
          .subscriptionPlanUserStatusId == 3 &&
        subscription.active == 1
      ) {
        isActive = true;
      }
      return isActive;
    },
    subscriptionActive(subscription) {
      let isActive = false;
      let userSubscription = null;
      this.userSubscriptions.filter(ub => {
        if (ub.subscriptionPlanUsers) {
          return ub.subscriptionPlanUsers.filter(ss => {
            if (ub.subscriptionPlanId == subscription.subscriptionPlanId) {
              userSubscription = ss;
            }
          });
        }
      });
      if (
        userSubscription &&
        userSubscription.active == 1 &&
        userSubscription.subscriptionPlanUserStatus &&
        userSubscription.subscriptionPlanUserStatus
          .subscriptionPlanUserStatusId == 8 &&
        subscription.active == 1
      ) {
        isActive = true;
      }
      return isActive;
    },
    subscriptionAdded(subscriptionPlanId) {
      let result = false;
      for (var i = 0; this.cart.cartItems.length > i; i++) {
        if (
          this.cart.cartItems[i].cartItemInfo.SUBSCRIPTION_PLAN_ID ==
          subscriptionPlanId
        ) {
          result = true;
          break;
        }
      }
      return result;
    },
    subscriptionPaid(subscription) {
      let subscriptionPaid = true;
      let userSubscription = null;
      this.userSubscriptions.filter(ub => {
        if (ub.subscriptionPlanUsers) {
          return ub.subscriptionPlanUsers.filter(ss => {
            if (ub.subscriptionPlanId == subscription.subscriptionPlanId) {
              userSubscription = ss;
            }
          });
        }
      });
      if (
        userSubscription &&
        userSubscription.active == 1 &&
        userSubscription.subscriptionPlanUserStatus &&
        userSubscription.subscriptionPlanUserStatus
          .subscriptionPlanUserStatusId == 3 &&
        subscription.active == 1
      ) {
        subscriptionPaid = false;
      }
      return subscriptionPaid;
    },
    isExpired(subscription) {
      let userSubscription = null;
      this.userSubscriptions
        .filter(
          ub =>
            ub.subscriptionPlanId === subscription.subscriptionPlanId &&
            ub.subscriptionPlanUsers.length > 0 // Check if subscriptionPlanUsers is not empty
        )
        .forEach(ub => {
          // Loop through subscriptionPlanUsers if the filter passed
          ub.subscriptionPlanUsers.forEach(ss => {
            userSubscription = ss;
          });
        });
      let result = false;
      /*let endDate = this.$dayjs(userSubscription.endDate).format("YYYY-MM-DD");*/
      // let today = this.$dayjs().format("YYYY-MM-DD");
      // endDate = "2024-06-10";
      /*let days = this.$dayjs(endDate).diff(this.$dayjs(today), "day");*/
      // let renewalDateEx = this.$dayjs(userSubscription.endDate).format(
      //   "YYYY-MM-DD"
      // );
      if (userSubscription.active === 1) {
        subscription.renewalDate = this.$dayjs(userSubscription.endDate).format(
          "YYYY-MM-DD"
        );
      }

      if (
        !userSubscription.active ||
        (userSubscription.active && userSubscription.active === 0) ||
        (userSubscription.active &&
          userSubscription.active === 1 &&
          this.$dayjs(userSubscription.endDate).format("YYYY-MM-DD") <
            this.$dayjs().format("YYYY-MM-DD"))
      ) {
        result = true;
      }
      return result;
    },
    isExpiring(subscription) {
      let userSubscription = null;
      this.userSubscriptions
        .filter(
          ub =>
            ub.subscriptionPlanId === subscription.subscriptionPlanId &&
            ub.subscriptionPlanUsers.length > 0 // Check if subscriptionPlanUsers is not empty
        )
        .forEach(ub => {
          // Loop through subscriptionPlanUsers if the filter passed
          ub.subscriptionPlanUsers.forEach(ss => {
            userSubscription = ss;
          });
        });
      let result = false;
      /*let endDate = this.$dayjs(userSubscription.endDate).format("YYYY-MM-DD");*/
      // let today = this.$dayjs().format("YYYY-MM-DD");
      // endDate = "2024-06-10";
      /*let days = this.$dayjs(endDate).diff(this.$dayjs(today), "day");*/
      // let renewalDate = this.$dayjs(userSubscription.endDate);
      if (userSubscription.active === 1) {
        subscription.renewalDate = this.$dayjs(userSubscription.endDate);
      }
      if (
        userSubscription.active === 1 &&
        this.$dayjs(userSubscription.endDate) &&
        subscription.renewalDaysBefore >=
          this.$dayjs(userSubscription.endDate).diff(
            this.$dayjs().format("YYYY-MM-DD"),
            "day"
          )
      ) {
        result = true;
      }
      return result;
    },
    async reload() {
      this.loading = true;
      await this.getSubscriptionsList();
      await this.getUserSubscriptionsList();
      this.loading = false;
    }
  },
  async mounted() {
    await this.reload();
  },
  created() {
    let vm = this;
    global.EventBus.$on("deleteSubscriptionPlan", subscriptionPlans => {
      vm.loadCart();
      this.subscriptions = subscriptionPlans;
      // vm.reload();
    });
  }
};
</script>
