import axios from "~/plugins/axios";

export default {
  subscriptionPlanList() {
    let url = "/ebsn/api/subscription-plan/list";
    return axios
      .get(url)
      .then(response => {
        return response.data.data;
      })
      .catch(error => error);
  },
  userSubscriptionPlanList() {
    let url = "/ebsn/api/subscription-plan/list?details=true";
    return axios
      .get(url)
      .then(response => {
        return response.data.data;
      })
      .catch(error => error);
  },
  addSubscriptionPlan(subscriptionPlan) {
    let url = "/ebsn/api/subscription-plan/add";
    return axios
      .post(url, subscriptionPlan)
      .then(response => {
        return response.data;
      })
      .catch(error => error);
  },
  deleteSubscriptionPlan(subscriptionPlan) {
    let url = "/ebsn/api/subscription-plan/delete";
    return axios
      .post(url, subscriptionPlan)
      .then(response => {
        return response.data;
      })
      .catch(error => error);
  }
};
